import { isAndroidPlatform } from '../platform'
import { allowedDataAttributes, dataConstants } from './attributesHelper'
import { isBlockNode, isListNode, isNonEditableNode, isPlainDivNode } from './findNodeHelper'
import { getTextContent } from './innerTextHelper'
import { childNodeIndexOf } from './rangeSerializer'

export const inlineTags = new Set([
  'B', 'I', 'U', 'SPAN'
])

export const isTouchSupported = 'ontouchstart' in document.documentElement
const { dataChecked, dataKeepColors, dataUpNoteKeepStyle } = dataConstants

export const allowedTags = [
  'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
  'div', 'span', 'br', 'hr', '#text',
  'b', 'strong', 'i', 'em', 'u', 'strike', 's', 'del',
  'ul', 'ol', 'li',
  'a', 'img', 'blockquote',
  'big', 'small', 'sup', 'sub', 'center',
  'pre', 'code',
  'table', 'tr', 'th', 'td', 'thead',
  'audio', 'source', 'video',
  'colgroup', 'col', 'object', 'iframe',
  'p', 'dt', 'article', 'section'
]

export const allowedAttributes = [
  'style', 'title',
  'width', 'height', 'src', 'alt',
  'href', 'class',
  'controls', 'type', 'preload',
  'rowspan', 'colspan', 'spellcheck',
  'allow', 'allowfullscreen', 'frameborder',
  'loading', 'name', 'referrerpolicy', 'data', 'start'
]

export const allowedAttributesSet = new Set(allowedAttributes)

/**
 * 
 * @param {import('../shine').default} shine 
 * @param {Node} node 
 * @returns 
 */
export const isNodeAtStartOfLine = (shine, node) => {
  if (node !== null && node !== undefined) {
    const root = shine.root

    // The node is at start of the line if the previous sibling
    // is a block node or is a first child of block node. If
    // the previous node is inline node, node will be in a new line
    // if it's a block node.
    const prev = getAdjacentNode(node, root, false)
    if (!prev) {
      return true
    }

    if (isBlockNode(prev) || prev instanceof HTMLBRElement) {
      return true
    }

    if (findNodeWithCondition(node, isBlockNode, root)) {
      return true
    }
  }
  return false
}

/**
 * 
 * @param {import('../shine').default} shine 
 */
export const isCursorAtStartOfLine = (shine) => {
  const range = shine.getSelectionRange()
  if (range) {
    const isZero = range.endOffset === 0

    const isTextNode = range.endContainer.nodeType === Node.TEXT_NODE
    const isOne = range.endOffset === 1 && !isTextNode

    if (isZero || isOne) {
      const end = range.endContainer
      if (isNodeAtStartOfLine(shine, end)) {
        if (isZero) {
          return true
        }
        if (isOne) {
          return isEmptyOrBlankNode(end)
        }
      }
    }
  }
  return false
}

/**
 * 
 * @param {Range} range 
 * @param {Element} block 
 * @param {Node} root
 */
export function isCursorAtStartOfBlock (range, block, root) {
  if (!range || !range.collapsed) return false
  const endContainer = range.endContainer

  if (endContainer instanceof Text && range.endOffset !== 0) {
    return false
  }

  const end = getNodeAtRangeOffset(range, root, false)
  const index = childNodeIndexOf(end)
  if (range.endOffset !== index) {
    return false
  }

  return isNodeFirstLine(end, block)
}

/**
 * 
 * @param {Range} range 
 * @param {Element} block 
 * @param {Node} root
 */
export function isCursorAtEndOfBlock (range, block, root) {
  if (!range || !range.collapsed) return false
  const endContainer = range.endContainer

  if (endContainer instanceof Text && range.endOffset !== getTextContent(endContainer).length) {
    return false
  }

  const end = getNodeAtRangeOffset(range, root, false)
  if (end === block || !block.contains(end)) {
    return false
  }

  if (getAdjacentNode(end, root, true)) {
    return false
  }

  return true
}

/**
 * 
 * @param {Node} node 
 * @param {Element | DocumentFragment} root 
 */
export function isNodeFirstLine (node, root) {
  if (!node) return false
  if (node === root || !root.contains(node)) return false

  if (getAdjacentNode(node, root, false)) {
    return false
  }

  return true
}

/**
 * 
 * @param {Node} node 
 * @param {Node} root 
 * @param {boolean} next
 */
export function getAdjacentNode (node, root, next = true) {
  let adjacentNode
  if (!next) {
    adjacentNode = node.previousSibling
  } else {
    adjacentNode = node.nextSibling
  }
  let parent = node.parentNode
  while (!adjacentNode && parent && parent !== root) {
    if (!next) {
      adjacentNode = parent.previousSibling
    } else {
      adjacentNode = parent.nextSibling
    }
    parent = parent.parentNode
  }
  return adjacentNode
}

/**
 * 
 * @param {Node} node 
 */
function nextVisibleSibling (node) {
  let n = node.nextSibling
  while (n) {
    /**@type {HTMLElement | undefined} */
    let el

    if (n instanceof HTMLElement) {
      el = n
    } else {
      const parent = n.parentNode
      if (parent instanceof HTMLElement) {
        el = parent
      }
    }

    if (el && el.offsetParent) {
      return n
    }
    
    n = n.nextSibling
  }

  return null
}

/**
 * 
 * @param {Node} node 
 * @param {Node} root 
 * @returns 
 */
export function isNodeAtTheEndOfRoot (node, root) {
  /** @type {Node | null} */
  let n = node
  while (n && n !== root) {
    const next = nextVisibleSibling(n)
    if (next) {
      if (next instanceof Element) {
        return false
      } else if (next.textContent && next.textContent.trim()) {
        return false
      }
    }
    n = n.parentNode
  }
  
  return true
}

/**
 * 
 * @param {Range} range 
 * @param {Node} root 
 * @returns {Node[]}
 */
export function getSelectedNodesInRange (range, root) {
  if (!range) {
    return []
  }
  const start = getNodeAtRangeOffset(range, root, true)
  const end = getNodeAtRangeOffset(range, root, false)

  // Special case for a range that is contained within a single node
  if (start === end) {
    const parent = start.parentNode
    
    if (start instanceof Text && parent && parent !== root) {
      return [parent]
    }

    return [start]
  }

  /** @type {Node | null} */
  let node = start

  // Iterate nodes until we hit the end container
  const rangeNodes = []

  let lastNode = start
  while (node && 
      node !== end && 
      node.parentNode !== end &&
      !node.contains(end) &&
      isBeforeReferenceNode(node, end)) {
    rangeNodes.push(node)
    lastNode = node
    node = getAdjacentNode(node, root, true)
  }

  // Add partially selected end node
  if (lastNode) {
    const partiallyEndNodes = [end]
    node = end.previousSibling
    if (!node) {
      node = end.parentNode
    }

    while (node && 
      node !== range.commonAncestorContainer &&
      node !== root &&
      isBeforeReferenceNode(lastNode, node)) {
      partiallyEndNodes.push(node)
      node = getAdjacentNode(node, root, false)
    }

    partiallyEndNodes.reverse()
    rangeNodes.push(...partiallyEndNodes)  
  }

  return rangeNodes
}

/**
 * 
 * @param {Node} node 
 * @param {Node} reference 
 * @returns 
 */
export function isBeforeReferenceNode (node, reference) {
  const compare = node.compareDocumentPosition(reference) & Node.DOCUMENT_POSITION_FOLLOWING
  return compare === Node.DOCUMENT_POSITION_FOLLOWING
}

/**
 * 
 * @param {HTMLElement | DocumentFragment} el 
 * @param {Set<string>} ignoreTags
 * @returns 
 */
export function getAllTextNodesInsideElement (el, ignoreTags) {
  const textNodes = []
  if (el && el.childNodes) {
    for (const child of el.childNodes) {
      if (child instanceof Text) {
        textNodes.push(child)
      } else if (child instanceof HTMLElement && !ignoreTags.has(child.nodeName)) {
        const arr = getAllTextNodesInsideElement(child, ignoreTags)
        textNodes.push(...arr)
      }
    }
  }
  return textNodes
}

/**
 * 
 * @param {Node} node 
 * @param {Node} reference 
 */
function isBeforeOrWithinReferenceNode (node, reference) {
  return reference.contains(node) || isBeforeReferenceNode(node, reference)
}

/**
 * 
 * @param {Node} node 
 * @param {Node} boundary 
 * @param {Node} root
 * @returns {Text[]}
 */
export function getTextNodesBeforeBoundary (node, boundary, root) {
  const textNodes = []

  /** @type {Node | null} */
  let n = node

  const ignoredTags = new Set(['PRE', 'CODE'])

  while (n && isBeforeOrWithinReferenceNode(n, boundary)) {
    if (n instanceof Text) {
      textNodes.push(n)
    } else if (n instanceof HTMLElement) {
      const childArr = getAllTextNodesInsideElement(n, ignoredTags)
      if (childArr.length > 0) {
        textNodes.push(...childArr)
      }
    }

    let next = n.nextSibling
    let parent = n.parentNode
    while (!next && parent && parent !== root) {
      next = parent.nextSibling
      parent = parent.parentNode
    }

    n = next
  }

  return textNodes
}

// Check for character NOT in space list, 
// or &nbsp; (space with 160 code or \xa0)
const nonWhiteSpaceRegex = /\S|\xa0|\u2060/i

/**
 * 
 * @param {string | null} text 
 * @returns 
 */
export function isBlankTextContent (text) {
  if (!text) return true
  return !nonWhiteSpaceRegex.test(text)
}

/**
 * Check if the node doesn't have any child, or it
 * only contains empty text node.
 * @param {Node} node 
 * @returns 
 */
export function isEmptyOrBlankNode (node) {
  if (!node) {
    return false
  }

  const nodeName = node.nodeName

  if (nodeName === 'IMG' || nodeName === 'HR' || nodeName === 'BR') {
    return false
  }

  const isHTMLElement = node instanceof HTMLElement

  if (isHTMLElement || node instanceof DocumentFragment) {
    if (!node.firstChild) {
      return true
    }
  
    if (node.querySelector('img, br, hr, video, audio, source, iframe, object')) {
      return false
    }

    if (isHTMLElement) {
      const textContent = node.textContent
      if (!textContent) {
        return true
      }

      const whiteSpace = node.style.whiteSpace
      if (whiteSpace && whiteSpace !== 'normal') {
        return false
      }

      // Spaces in a block node doesn't have any effect.
      if (isBlockNode(node)) {
        return isBlankTextContent(textContent)
      }

      return false
    }
  }

  if (node.textContent) {
    return false
  }

  return true
}

/**
 * 
 * @param {Node} node 
 */
export function containsSingleBr (node) {
  if (node instanceof HTMLElement) {
    const firstEl = node.firstElementChild
    const lastEl = node.lastElementChild
    if (firstEl instanceof HTMLBRElement && firstEl === lastEl) {
      return true
    }
  }
  return false
}

/**
 * 
 * @param {Node} el 
 * @returns 
 */
export function getMostNestedFirstChild (el) {
  let firstChild = el.firstChild
  while (firstChild) {
    const child = firstChild.firstChild
    if (!child) {
      return firstChild
    }
    firstChild = child
  }
  return firstChild
}


/**
 * 
 * @param {Node} el 
 * @returns 
 */
export function getMostNestedLastChild (el) {
  let lastEl = el.lastChild
  while (lastEl) {
    const child = lastEl.lastChild
    if (!child) {
      return lastEl
    }
    lastEl = child
  }
  return lastEl
}

/**
 * 
 * @param {HTMLElement} el 
 * @returns 
 */
export function getMostNestedFirstElement (el) {
  let firstChild = el.firstElementChild
  while (firstChild) {
    const child = firstChild.firstElementChild
    if (!child) {
      return firstChild
    }
    firstChild = child
  }
  return firstChild
}


/**
 * 
 * @param {HTMLElement} el 
 * @returns 
 */
export function getMostNestedLastElement (el) {
  let lastEl = el.lastElementChild
  while (lastEl) {
    const child = lastEl.lastElementChild
    if (!child) {
      return lastEl
    }
    lastEl = child
  }
  return lastEl
}

/**
 * 
 * @param {Node} node 
 * @param {Node} root 
 * @param {boolean} ignoreClassName 
 * @returns {Node | null}
 */
export function removeNodeAndEmptyParent (node, root, ignoreClassName = false) {
  if (node instanceof HTMLBRElement || node instanceof HTMLHRElement) {
    return null
  }

  let n = node
  while (n && n !== root) {
    if (n.firstChild) {
      break
    }
    
    const parent = n.parentNode
    if (!parent) {
      break
    }

    if (findNodeInTags(n, ['PRE', 'CODE'])) {
      break
    }

    if (n instanceof Element) {
      if (!n.className || ignoreClassName) {
        n.remove()
      } else {
        break
      }
    } else {
      break
    }

    parent.normalize()
    n = parent
  }
  return n
}

/**
 * 
 * @param {Range?} range 
 */
export function updateSelectionRange (range) {
  const sel = window.getSelection()
  if (!sel) return
  sel.removeAllRanges()
  if (range) {
    sel.addRange(range)
  }
}

/**
 * 
 * @param {HTMLElement} node 
 * @param {string[]} attributeNames 
 * @returns 
 */
export function nodeContainsAnyAttributeNames (node, attributeNames) {
  for (const attr of Array.from(node.attributes)) {
    for (const name of attributeNames) {
      if (attr.nodeName.includes(name)) {
        return true
      }
    }
  }
  return false
}

/**
 * 
 * @param {Range} range 
 * @param {Node?} root
 * @param {boolean} shouldGetStartNode 
 * @returns 
 */
export function getNodeAtRangeOffset (range, root, shouldGetStartNode = true) {
  const start = range.startContainer
  const end = range.endContainer

  let n
  if (shouldGetStartNode) {
    n = start
  } else {
    n = end
  }

  if (n instanceof Element && 
    n.childNodes && 
    n.childNodes.length > 0 && 
    !(n instanceof HTMLBRElement)) {
    if (shouldGetStartNode || range.collapsed) {
      let child = n.childNodes[range.startOffset]
      if (!child) {
        child = n.childNodes[range.startOffset - 1]
      }
      if (child) {
        n = child
      }
    } else {
      const endOffset = range.endOffset
      let child
      if (endOffset > 0) {
        child = n.childNodes[endOffset - 1]
      } else {
        // User may select until next line - in this case do not
        // limit the selection until end of line.
        if (start !== end) {
          let prev = end.previousSibling
          let parent = end.parentNode
          while (!prev && parent && parent !== root) {
            prev = parent.previousSibling
            parent = parent.parentNode
          }
          if (prev) {
            child = prev
          }
        }
        if (!child) {
          child = n.childNodes[0]
        }
      }
      if (child) {
        n = child
      }
    }
  }
  return n
}

/**
 * 
 * @param {import('../shine').default} shine 
 * @returns 
 */
export function selectParagraph (shine) {
  const range = shine.getSelectionRange()
  if (!range) {
    return
  }

  const root = shine.root
  const start = getNodeAtRangeOffset(range, root, true)
  const endContainer = range.endContainer
  const end = getNodeAtRangeOffset(range, root, false)

  let startOfLine
  let endOfLine

  /** @type {Node | null} */
  let n = start

  while (n && n !== shine.root) {
    if (n instanceof Element && isBlockNode(n)) {
      startOfLine = n
      break
    }

    /** @type {Node | null} */
    let prevNode = n.previousSibling
    if (!prevNode) {
      prevNode = n.parentNode
    }
    if (prevNode) {
      const nodeName = prevNode.nodeName
      if (nodeName && (isBlockNode(prevNode) || nodeName === 'BR')) {
        startOfLine = n
        break
      }
      n = prevNode
    } else {
      break
    }
  }

  n = end

  // If user tripple clicks on a line, the selection will go over to the next line,
  // cause the endOffset becomes 0. In this case needs to look for previous node
  // of endContainer.
  let isSelectionUntilStartOfNextLine = false
  if (end !== start) {
    if (end instanceof HTMLBRElement) {
      endOfLine = end
    } else if (range.endOffset === 0 && isBlockNode(endContainer)) {
      n = endContainer
      isSelectionUntilStartOfNextLine = true
    }
  }
  
  if (!endOfLine) {
    if (isSelectionUntilStartOfNextLine) {
      while (n && n !== shine.root) {
        const prevNode = n.previousSibling
        if (prevNode) {
          endOfLine = prevNode
          break
        }
        n = n.parentNode
      }
    } else {
      while (n && n !== shine.root) {
        if (n instanceof Element && isBlockNode(n)) {
          endOfLine = n
          break
        }
    
        /** @type {Node | null} */
        let nextNode = n.nextSibling
        if (!nextNode) {
          nextNode = n.parentNode
        }
        if (nextNode) {
          const nodeName = nextNode.nodeName
          if (nodeName && (isBlockNode(nextNode) || nodeName === 'BR')) {
            endOfLine = n
            break
          }
          n = nextNode
        } else {
          break
        }
      }
    }
  }

  adjustRangeFromStartToEnd(range, startOfLine, endOfLine)
}

/**
 * @param {Range | null} range
 * @param {Node | null | undefined} start 
 * @param {Node | null | undefined} end 
 */
export function adjustRangeFromStartToEnd (range, start, end) {
  if (start) {
    let newRange
    if (start === end) {
      newRange = document.createRange()
      newRange.selectNode(start)
    } else {
      if (range) {
        newRange = range.cloneRange()
      } else {
        newRange = document.createRange()
      }
  
      const startParent = start.parentNode
      if (startParent && 
        (start instanceof HTMLBRElement || start instanceof HTMLHRElement)) {
        const index = childNodeIndexOf(start)
        if (index !== -1) {
          newRange.setStart(startParent, index)
        }
      } else {
        newRange.setStart(start, 0)
      }
      
      if (end) {
        if (end instanceof Text) {
          newRange.setEnd(end, end.length)
        } else if (end.childNodes.length > 0) {
          newRange.setEnd(end, end.childNodes.length)
        } else {
          const index = childNodeIndexOf(end)
          const endParent = end.parentNode
          if (endParent && index !== -1) {
            newRange.setEnd(endParent, index + 1)
          }
        }
      }
    }

    updateSelectionRange(newRange)
  }
}

/**
 * 
 * @param {Node} start 
 * @param {Node} end 
 */
export function selectRangeFromStartToEnd (start, end) {
  adjustRangeFromStartToEnd(null, start, end)
}

/**
 * When paste content into editor, need to limit the range
 * so it doesn't span across invalid elements, for e.g across
 * table cells.
 * @param {Range} range 
 * @param {Node} root 
 * @returns 
 */
export function limitRangeIfNeeded (range, root) {
  if (!range) return range

  const start = range.startContainer
  const end = range.endContainer

  if (range.collapsed) {
    if (start instanceof HTMLBRElement || start instanceof HTMLHRElement) {
      const newRange = document.createRange()
      newRange.selectNode(start)
      newRange.collapse(true)
      updateSelectionRange(newRange)
      return newRange
    }

    return range
  }

  const sel = window.getSelection()
  if (!sel) return range

  const text = sel.toString()
  if (isBlankTextContent(text)) {
    const newRange = range.cloneRange()
    newRange.collapse(true)
    updateSelectionRange(newRange)
    return newRange
  }


  if (start === end) return range

  const startCell = findNodeWithTag(start, 'TD', root)
  if (!startCell) return range

  const endCell = findNodeWithTag(end, 'TD', root)
  if (!endCell) return range

  if (startCell !== endCell) {
    const newRange = range.cloneRange()
    newRange.setEnd(startCell, startCell.childNodes.length)
    updateSelectionRange(newRange)
    return newRange
  }

  return range
}

export const noWidthTagNames = new Set([
  'DIV', 'SPAN',
  'H1', 'H2', 'H3', 'H4', 'H5', 'H6',
  'UL', 'OL', 'LI', 'IMG', 'TD', 'TH', 'TABLE'
])

const allowedWhiteSpaces = new Set(['pre-wrap', 'pre-line', 'break-spaces'])

/**
 * 
 * @param {Node} node 
 * @returns 
 */
export function removeStyle (node) {
  if (!node || !(node instanceof HTMLElement)) {
    return
  }

  if (node.hasAttribute(dataUpNoteKeepStyle)) {
    return
  }
  
  const style = node.style
  if (style && style.length > 0) {
    const nodeName = node.nodeName

    const width = style.width
    const textAlign = style.textAlign
    const textColor = style.color
    const backgroundColor = style.backgroundColor
    const whiteSpace = style.whiteSpace

    // Clear style
    removeAttributeIfNeeded(node, 'style')
    removeAttributeIfNeeded(node, 'color')

    // Keep selected attributes if needed
    if (width) {
      if (!noWidthTagNames.has(nodeName)) {
        node.style.width = width
      }
    }

    if (textAlign) {
      let shouldKeepTextAlign = true
      if (textAlign === 'left' || textAlign === 'start') {
        shouldKeepTextAlign = false
        let parent = node.parentNode
        while (parent && parent instanceof HTMLElement) {
          const classList = parent.classList
          if (classList && classList.contains('shine-editor')) {
            break
          }
          const pTextAlign = parent.style.textAlign
          if (pTextAlign && pTextAlign !== 'left' && pTextAlign !== 'start') {
            shouldKeepTextAlign = true
            break
          }
          parent = parent.parentNode
        }
      }
      if (shouldKeepTextAlign) {
        node.style.textAlign = textAlign
      }
    }

    if (whiteSpace && allowedWhiteSpaces.has(whiteSpace) &&
      nodeName !== 'PRE' && nodeName !== 'CODE') {
      node.style.whiteSpace = whiteSpace
    }

    const keepColors = node.getAttribute(dataKeepColors)
    if (keepColors) {
      if (textColor) {
        node.style.color = textColor
      }
      if (backgroundColor) {
        node.style.backgroundColor = backgroundColor
      }
    }
  }
}

/**
 * 
 * @param {Node} node 
 */
export function removeStyleForNodeAndChildren (node) {
  if (node instanceof HTMLElement) {
    const childElements = node.querySelectorAll('*')
    for (const child of childElements) {
      removeStyle(child)
    }
    removeStyle(node)
  }
}

/**
 * 
 * @param {HTMLTableElement} table 
 * @returns 
 */
export function sanitizeTable (table) {
  if (!table.rows || table.rows.length === 0) {
    return
  }

  // Remove empty row
  for (const row of table.rows) {
    if (row.cells.length === 0) {
      row.remove()
    }
  }

  // Calculate number of columns in each row
  const numberOfColumnArr = new Array(table.rows.length).fill(0)
  for (const row of table.rows) {
    for (const c of row.cells) {
      const colSpan = c.colSpan? c.colSpan : 1
      const rowSpan = c.rowSpan? c.rowSpan : 1
      for (let i = 0; i < rowSpan; i++) {
        // Need to make sure the rowIndex does not exceed table
        // rows because colSpan / rowSpan can sometime be invalid
        if (row.rowIndex + i < table.rows.length) {
          const n = numberOfColumnArr[row.rowIndex + i]
          numberOfColumnArr[row.rowIndex + i] = n + colSpan
        }
      }
    }
  }

  // Total number of columns is maximum number of columns
  // on each row
  const numberOfColumn = Math.max(...numberOfColumnArr)

  let shouldSetupColgroup = false
  const colgroup = table.getElementsByTagName('colgroup')[0]
  if (!colgroup) {
    shouldSetupColgroup = true
  } else {
    const cols = colgroup.getElementsByTagName('col')
    let isInvalidColumn = false
    if (!cols || cols.length !== numberOfColumn) {
      isInvalidColumn = true
    } else {
      for (const col of cols) {
        if (!col.style || !col.style.width || !col.style.width.endsWith('px')) {
          isInvalidColumn = true
          break
        }
      }
    }
    if (isInvalidColumn) {
      colgroup.remove()
      shouldSetupColgroup = true
    }
  }

  if (shouldSetupColgroup) {
    if (colgroup) {
      colgroup.remove()
    }
    const newColgroup = document.createElement('colgroup')

    for (let i = 0; i < numberOfColumn; i++) {
      const col = document.createElement('col')
      col.style.width = '160px'
      newColgroup.append(col)
    }

    table.prepend(newColgroup)
  }

  // Insert missing cells
  for (const row of table.rows) {
    const insertCount = numberOfColumn - numberOfColumnArr[row.rowIndex]
    for (let i = 0; i < insertCount; i++) {
      insertTableCell(row, -1)
    }
  }
}

/**
 * 
 * @param {HTMLTableRowElement} row 
 * @param {number} position 
 * @returns 
 */
export function insertTableCell (row, position) {
  if (position > row.cells.length) {
    return null
  }
  const cell = row.insertCell(position)
  const br = document.createElement('br')
  cell.append(br)
  return cell
}

/**
 * 
 * @param {Node} node 
 * @returns 
 */
export function cloneChildNodesIntoFragment (node) {
  const fragment = document.createDocumentFragment()
  for (const child of node.childNodes) {
    fragment.append(child.cloneNode(true))
  }
  return fragment
}

/**
 * 
 * @param {Node} node 
 * @returns 
 */
export function moveChildNodesIntoFragment (node) {
  const fragment = document.createDocumentFragment()
  // Convert childNodes into non-live array
  const arr = Array.from(node.childNodes)
  for (const child of arr) {
    child.remove()
  }
  fragment.append(...arr)
  return fragment
}

/**
 * 
 * @param {Node} node 
 */
export function removeAllChildNodes (node) {
  // Convert childNodes into non-live array
  const arr = Array.from(node.childNodes)
  for (const child of arr) {
    child.remove()
  }
}

/**
 * 
 * @param {Node} node 
 * @param {(node: Node) => boolean} conditionFn 
 * @param {Node | null} boundary 
 * @returns 
 */
export function findNodeWithCondition (node, conditionFn, boundary = null) {
  if (!node) return null

  /** @type {Node | null} */
  let currentNode = node
  if (node instanceof Text) {
    currentNode = node.parentNode
  }
  if (boundary && currentNode && !boundary.contains(currentNode)) {
    return null
  }

  while (currentNode) {
    if (boundary) {
      if (boundary === currentNode) {
        return null
      }
    } else {
      const classList = /** @type {HTMLElement} */ (currentNode).classList
      if (classList && classList.contains('shine-editor')) {
        return null
      }
    }

    if (conditionFn(currentNode) === true) {
      return currentNode
    }
    currentNode = currentNode.parentNode
  }

  return null
}

/**
 * 
 * @param {Node} node 
 * @param {string} tagName 
 * @param {Node?} boundary 
 * @returns 
 */
export function findNodeWithTag (node, tagName, boundary = null) {
  const conditionFn = (/**@type {Node} */ currentNode) => {
    return currentNode.nodeName === tagName
  }
  return findNodeWithCondition(node, conditionFn, boundary)
}

/**
 * 
 * @param {Node} node 
 * @param {string[] | Set<string>} tagNames 
 * @param {Node?} boundary 
 * @returns 
 */
export function findNodeInTags (node, tagNames, boundary = null) {
  const set = new Set(tagNames)
  const conditionFn = (/**@type {Node} */ currentNode) => {
    return set.has(currentNode.nodeName)
  }
  return findNodeWithCondition(node, conditionFn, boundary)
}

/**
 * 
 * @param {Node} node 
 * @returns 
 */
export function isNodeEndsWithNewLine (node) {
  // Check if lastChild is <br> or contains <br> element
  if (node instanceof HTMLBRElement) {
    return true
  }

  if (node.lastChild && node.lastChild instanceof HTMLBRElement) {
    return true
  }

  const text = node.textContent
  if (text && text.endsWith('\n')) {
    return true
  }
  
  return false
}

/**
 * 
 * @param {Node} node 
 * @param {boolean} shouldCollapse 
 * @param {boolean} collapseToStart 
 * @returns 
 */
export function selectNodeContents (node, shouldCollapse = true, collapseToStart = false) {
  if (!node || !node.isConnected) return
  const range = document.createRange()

  let shouldSelectContents = true
  if (node instanceof HTMLImageElement ||
    node instanceof HTMLBRElement ||
    node instanceof HTMLHRElement ||
    isNonEditableNode(node)) {
    shouldSelectContents = false
  }

  if (shouldSelectContents) {
    range.selectNodeContents(node)
  } else {
    range.selectNode(node)
  }

  if (shouldCollapse) {
    // For Korean text input, if the range is already collapsed and the
    // lastEl is blank, do not need to update the range. Otherwise,
    // when composing Korean text, the first character composition session
    // is automatically ended.
    let updatedColapseToStart = collapseToStart
    if (shouldSelectContents && isEmptyOrBlankNode(node)) {
      updatedColapseToStart = true
    }
    range.collapse(updatedColapseToStart)
  }
  
  updateSelectionRange(range)
}

/**
 * 
 * @param {MouseEvent | TouchEvent} e 
 * @returns {number | null}
 */
export function getEventClientX (e) {
  if (window.TouchEvent && e instanceof window.TouchEvent) {
    if (e.touches && e.touches.length > 0) {
      return e.touches[0].clientX
    }
  }
  if (e instanceof MouseEvent) {
    return e.clientX
  }
  return null
}

/**
 * 
 * @param {MouseEvent | TouchEvent} e 
 * @returns {number | null}
 */
export function getEventClientY (e) {
  if (window.TouchEvent && e instanceof window.TouchEvent) {
    if (e.touches && e.touches.length > 0) {
      return e.touches[0].clientY
    }
  }
  if (e instanceof MouseEvent) {
    return e.clientY
  }
  return null
}

const nonEmptyTags = [
  'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
  'div', 'span', 'b', 'i', 'u', 's',
  'del', 'a', 'blockquote', 'pre', 'code', 
  'ul', 'ol', 'li'
]

const emptySelector = nonEmptyTags.map(x => `${x}:empty`).join(',')

/**
 * 
 * @param {Element | DocumentFragment} root 
 * @param {boolean} ignoreClassName 
 */
export function removeEmptyElements (root, ignoreClassName = false) {
  const emptyEls = Array.from(root.querySelectorAll(emptySelector))
  for (const el of emptyEls) {
    removeNodeAndEmptyParent(el, root, ignoreClassName)
  }
}

/**
 * 
 * @param {import('../shine').default} shine 
 * @returns 
 */
export function scrollAndroidEditorIfNeeded (shine) {
  if (!isAndroidPlatform()) {
    return
  }
  const range = shine.getSelectionRange()
  if (!range) return
  const rect = getRangeBoundingRect(range)
  if (!rect) {
    return
  }
  const style = getComputedStyle(document.documentElement)
  const lineHeight = parseFloat(style.getPropertyValue('--line-height'))
  const fontSize = parseFloat(style.getPropertyValue('--font-size'))
  const lineHeightPx = lineHeight * fontSize
  const windowHeight = window.innerHeight

  if (rect.top + lineHeightPx > windowHeight) {
    let bottom = rect.top + lineHeightPx + 5
    const scrollHeight = document.documentElement.scrollHeight
    if (bottom > scrollHeight) {
      bottom = scrollHeight
    }
    window.scrollBy(0, bottom - windowHeight + 100)
    return
  }

  if (rect.top < 0) {
    const distance = 50 + Math.abs(rect.top)
    // Distance is negative because window need to scroll up.
    window.scrollBy(0, -distance)
  }
}

/**
 * 
 * @param {HTMLElement} el 
 * @param {string} type 
 * @returns 
 */
export function getCoordinateOfPseudoElement (el, type = ':before') {
  const style = window.getComputedStyle(el, type)
  return {
    width: parseInt(style.width),
    height: parseInt(style.height),
    marginLeft: parseInt(style.marginLeft),
    marginRight: parseInt(style.marginRight),
    marginInlineStart: parseInt(style.marginInlineStart),
    marginInlineEnd: parseInt(style.marginInlineEnd),
    marginTop: parseInt(style.marginTop),
    marginBottom: parseInt(style.marginBottom),
    left: parseInt(style.left),
    top: parseInt(style.top),
    right: parseInt(style.right),
    bottom: parseInt(style.bottom)
  }
}

/**
 * 
 * @param {string} text 
 * @param {string} openString 
 * @param {string} endString 
 * @returns 
 */
export function extractLastIndexOfPrefix (text, openString, endString) {
  let index = -1
  if (openString !== endString) {
    index = text.lastIndexOf(openString)
  } else {
    const pos = text.lastIndexOf(openString)
    if (pos !== -1) {
      index = text.substring(0, pos).lastIndexOf(openString)
    }
  }
  return index
}

/**
 * 
 * @param {Range} range 
 * @param {string} openString 
 * @param {string} endString 
 * @returns 
 */
export function extractTextDataAtRange (range, openString, endString) {
  if (!range || !range.collapsed) {
    return null
  }

  const start = getNodeAtRangeOffset(range, null, true)
  if (start.nodeType !== Node.TEXT_NODE) {
    return null
  }

  // Look for the text starts at the beginning of node
  // or after a space
  const textContent = start.textContent || ''
  const text = textContent.substring(0, range.startOffset)
  const startIndex = extractLastIndexOfPrefix(text, openString, endString)

  if (startIndex !== -1) {
    let extractedText = text.substring(startIndex + openString.length)
    const endIndex = extractedText.lastIndexOf(endString)
    let isCompleted = false
    let isClosing = false

    if (endIndex !== -1) {
      // If user typed ] and then type a different character, finish
      // the back link session
      const suffix = extractedText.substring(endIndex)
      if (!endString.startsWith(suffix)) {
        return null
      }
      extractedText = extractedText.substring(0, endIndex)
      isCompleted = suffix.length === endString.length
      isClosing = true
    }

    return {
      text: extractedText.trim(),
      isCompleted: isCompleted,
      isClosing: isClosing
    }
  }

  return null
}

/**
 * 
 * @param {Element} fromNode 
 * @param {Element} toNode 
 * @param {((node: Node) => boolean)?} conditionFn 
 * @returns 
 */
export function copyAttributes (fromNode, toNode, conditionFn = null) {
  const attrs = Array.from(fromNode.attributes)
  if (!attrs) return
  for (const attr of attrs) {
    let shouldCopy = true
    if (conditionFn) {
      shouldCopy = conditionFn(attr)
    }
    if (shouldCopy) {
      const nodeValue = attr.nodeValue
      if (nodeValue) {
        toNode.setAttribute(attr.nodeName, nodeValue)
      }
    }
  }
}

/**
 * 
 * @param {Element} node 
 */
export function removeAllAttributes (node) {
  if (!node || !node.attributes || !node.removeAttribute) return
  while (node.attributes.length > 0) {
    node.removeAttribute(node.attributes[0].name)
  }
}

/**
 * Wait for image loaded, maximum at 5000ms
 * @param {HTMLImageElement} img 
 * @returns 
 */
export async function waitForImageLoaded (img) {
  if (img.complete) return

  /** @type {Promise<void>} */
  const maxWait = new Promise(resolve => {
    setTimeout(resolve, 5000)
  })

  /** @type {Promise<void>} */
  const prom = new Promise(resolve => {
    const listener = () => {
      img.removeEventListener('load', listener)
      img.removeEventListener('error', listener)
      resolve()
    }
    img.addEventListener('load', listener)
    img.addEventListener('error', listener)
  })
  await Promise.any([maxWait, prom])
}

// Deferred script is ready to used only when the readyState
// is 'complete'
export async function waitForDOMReady () {
  if (document.readyState !== 'complete') {
    await new Promise(/** @type {() => void} */ resolve => {
      const listener = () => {
        if (document.readyState === 'complete') {
          document.removeEventListener('readystatechange', listener)
          resolve()
        }
      }
      document.addEventListener('readystatechange', listener)
    })
  }
}

/**
 * 
 * @param {InputEvent} e 
 * @returns {boolean}
 */
export function isEnterEvent (e) {
  if (e.inputType === 'insertParagraph') {
    return true
  }
  if (e.inputType === 'insertText') {
    return e.data === null
  }
  if (e.inputType === 'insertCompositionText' &&
    e.data &&
    e.data.endsWith &&
    e.data.endsWith('\n')) {
    return true
  }
  return false
}

/**
 * 
 * @param {string} inputType 
 */
export function isDeleteContentEvent (inputType) {
  return inputType === 'deleteContentBackward' || inputType === 'deleteContentForward'
}

/**
 * 
 * @param {DocumentFragment} fragment 
 * @param {HTMLLIElement} liNode 
 */
export function cleanupFragmentForListInsertion (fragment, liNode) {
  const isCheckbox = liNode.hasAttribute(dataChecked)

  // If user is inserting fragment into a list, make sure
  // the fragment contains only LI, OL or UL nodes
  const childNodes = Array.from(fragment.childNodes)
  for (const child of childNodes) {
    if (child instanceof HTMLLIElement) {
      if (isCheckbox && !child.hasAttribute(dataChecked)) {
        child.setAttribute(dataChecked, 'false')
      }
      continue
    }

    if (child instanceof HTMLUListElement || child instanceof HTMLOListElement) {
      const innerFragment = moveChildNodesIntoFragment(child)
      const innerChildNodes = Array.from(innerFragment.childNodes)
      for (const innerChild of innerChildNodes) {
        if (innerChild instanceof HTMLLIElement) {
          const innerLastChild = innerChild.lastChild
          if (innerLastChild && innerLastChild instanceof HTMLBRElement) {
            innerLastChild.remove()
          }
          if (isCheckbox && !innerChild.hasAttribute(dataChecked)) {
            innerChild.setAttribute(dataChecked, 'false')
          }
        }
      }
      child.replaceWith(innerFragment)
      continue
    }
    
    if (child.textContent) {
      const liNode = document.createElement('li')
      liNode.append(child.cloneNode(true))
      if (isCheckbox) {
        liNode.setAttribute(dataChecked, 'false')
      }
      child.replaceWith(liNode)
    } else {
      child.remove()
    }
  }
}

/**
 * 
 * @param {Node} child
 * @param {Node} root 
 * @returns 
 */
export function isSameOrChildOfNode (child, root) {
  return root === child || root.contains(child)
}

/**
 * 
 * @param {Element} root 
 * @returns 
 */
export function getRangeInNode (root) {
  const selection = window.getSelection()
  if (!selection || selection.rangeCount <= 0) return null
  const range = selection.getRangeAt(0)
  if (!range) return null

  const start = range.startContainer
  const end = range.endContainer

  if (isSameOrChildOfNode(start, root) &&
    isSameOrChildOfNode(end, root)) {
    return range
  }

  return null
}

/**
 * 
 * @param {Element} first 
 * @param {Element} second 
 * @returns 
 */
export function isSameAttributes (first, second) {
  if (first.attributes.length !== second.attributes.length) {
    return false
  }

  for (const attr of first.attributes) {
    const firstVal = attr.nodeValue
    const secondVal = second.getAttribute(attr.nodeName)
    if (firstVal !== secondVal) {
      return false
    }
  }

  return true
}

/**
 * 
 * @param {Node} node 
 * @param {string} attr 
 */
export function removeAttributeIfNeeded (node, attr) {
  if (!node || !(node instanceof Element)) {
    return
  }
  if (node.hasAttribute(attr)) {
    node.removeAttribute(attr)
  }
}

/**
 * 
 * @param {Node} node 
 * @param {string} cls 
 */
export function removeClassIfNeeded (node, cls) {
  if (!node || !(node instanceof Element)) {
    return
  }
  if (node.classList.contains(cls)) {
    node.classList.remove(cls)
    if (node.classList.length === 0) {
      removeAttributeIfNeeded(node, 'class')
    }
  }
}

/**
 * 
 * @param {Node} node 
 * @param {string} cls 
 */
export function addClassIfNeeded (node, cls) {
  if (!node || !(node instanceof Element)) {
    return
  }
  if (!node.classList.contains(cls)) {
    node.classList.add(cls)
  }
}

/**
 * 
 * @param {Range} range 
 */
export function deleteContentsFromRange (range) {
  const start = getNodeAtRangeOffset(range, null, true)
  const parent = start.parentNode
  if (!parent) return
  
  range.deleteContents()
  if (parent.childNodes.length === 0 && parent instanceof Element) {
    parent.remove()
  }
}

/**
 * 
 * @param {MouseEvent | TouchEvent} e 
 * @returns 
 */
export function getRangeAtEventPoint (e) {
  const clientX = getEventClientX(e)
  const clientY = getEventClientY(e)
  if (clientX !== null && clientY !== null) {
    return document.caretRangeFromPoint(clientX, clientY)
  }
  return null
}

/**
 * 
 * @param {MouseEvent | TouchEvent} e 
 */
export function setRangeAtEventPoint (e) {
  const range = getRangeAtEventPoint(e)
  if (range) {
    updateSelectionRange(range)
  }
}

/**
 * 
 * @param {Range} range 
 * @returns 
 */
export function getRangeBoundingRect (range) {
  let rangeRect = range.getBoundingClientRect()
  if (rangeRect.width === 0 && rangeRect.height === 0) {
    const start = getNodeAtRangeOffset(range, null, true)
    if (start) {
      if (start instanceof Element && 
        start.getAttribute('contenteditable') !== 'true') {
        rangeRect = start.getBoundingClientRect()
      } else if (start instanceof Text) {
        const parent = start.parentNode
        if (parent) {
          // Append a temporary span to get rect.
          const span = document.createElement('span')
          span.appendChild(document.createElement('br'))
          parent.insertBefore(span, start)
          rangeRect = span.getBoundingClientRect()
          span.remove()
        }
      }
    }
  }
  return rangeRect
}

/**
 * 
 * @param {Node} el 
 * @returns 
 */
export function flattenInlineNodesInBlockElement (el) {
  /** @type {Node[][]} */
  const inlineNodesArr = []

  /** @type {Node[] | undefined} */
  let inlineNodes
  for (const child of Array.from(el.childNodes)) {
    if (child instanceof HTMLBRElement) {
      inlineNodes = undefined
      inlineNodesArr.push([child])
    } else if (!isBlockNode(child)) {
      if (!inlineNodes) {
        inlineNodes = []
        inlineNodesArr.push(inlineNodes)
      }
      inlineNodes.push(child)
    } else {
      inlineNodes = undefined
      inlineNodesArr.push(...flattenInlineNodesInBlockElement(child))
    }
  }

  return inlineNodesArr
}

/**
 * https://github.com/olahol/scrollparent.js/blob/master/scrollparent.js
 * @param {Element} node 
 * @returns 
 */
function isScrolling (node) {
  var overflow = getComputedStyle(node, null).getPropertyValue('overflow')
  return overflow.indexOf('scroll') > -1 || overflow.indexOf('auto') > - 1
}

/**
 * 
 * @param {Element} node 
 * @returns 
 */
export function findScrollParent (node) {
  if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
    return undefined
  }

  let current = node.parentNode
  while (current && current.parentNode) {
    if (current instanceof Element && isScrolling(current)) {
      return current
    }

    current = current.parentNode
  }

  return document.scrollingElement || document.documentElement
}

/**
 * 
 * @param {Node} node 
 */
export function removeNode (node) {
  if (node instanceof Element || node instanceof Text) {
    node.remove()
  } else {
    const parent = node.parentNode
    if (parent instanceof Element) {
      parent.removeChild(node)
    }
  }
}

/**
 * 
 * @param {Node} node 
 * @param {Range} range 
 */
export function insertNodeToRange (node, range) {
  // Insert the fragment
  const sel = window.getSelection()
  if (!sel) return

  const start = range.startContainer
  let currentRange = range

  // Avoid a range inside the BR or HR
  if (range.collapsed && range.startOffset === 0) {
    if (start instanceof HTMLBRElement ||  start instanceof HTMLHRElement) {
      selectNodeContents(start, true, true)
      currentRange = sel.getRangeAt(0)
    }
  }

  currentRange.insertNode(node)
}

/**
 * Find the first list element, then merge all subsequent list elements
 * @param {HTMLUListElement | HTMLOListElement} list 
 */
function mergeAdjacentLists (list) {
  if (!isListNode(list)) {
    return
  }
  
  let firstList = list
  let prevSibling = list.previousSibling
  while (prevSibling && prevSibling.nodeName === list.nodeName) {
    firstList = /** @type {HTMLUListElement|HTMLOListElement} */(prevSibling)
    prevSibling = prevSibling.previousSibling
  }

  let nextSibling = firstList.nextSibling
  while (nextSibling && nextSibling.nodeName === list.nodeName) {
    const node = nextSibling
    nextSibling = nextSibling.nextSibling

    const fragment = moveChildNodesIntoFragment(node)
    firstList.append(fragment)
    node.remove()
  }
}

/**
 * 
 * @param {Element|DocumentFragment} topListNode 
 */
export function mergeNestedAdjacentLists (topListNode) {
  // Keep track of range so it can be restored later
  let rangeToken
  if (topListNode instanceof Element) {
    const range = getRangeInNode(topListNode)
    if (range) {
      rangeToken = getRangeRestorationState(range, topListNode)
    }
  }

  const allLists = topListNode.querySelectorAll('ul, ol')

  for (const list of Array.from(allLists)) {
    // Check if `list` is still inside `topListNode`
    if (topListNode.contains(list)) {
      const listNode = /** @type {HTMLUListElement|HTMLOListElement} */ (list)
      mergeAdjacentLists(listNode)
    }
  }

  if (rangeToken) {
    restoreRangeFromState(rangeToken)
  }
}

/**
 * 
 * @param {Element} node 
 * @param {(p: Node | null) => boolean} [parentConditionFn=isPlainDivNode] 
 */
export function simplifyNestedParent (node, parentConditionFn = isPlainDivNode) {
  let isSimplified = false
  let parent = node.parentNode
  while (parent instanceof HTMLElement && parentConditionFn(parent)) {
    const grandParent = parent.parentNode
    if (grandParent instanceof Element) {
      // Split the parent node into 3 separate nodes
      const topNode = document.createElement(parent.nodeName)
      copyAttributes(parent, topNode)
      for (const child of Array.from(parent.childNodes)) {
        if (child !== node) {
          child.remove()
          topNode.append(child)
        } else {
          break
        }
      }

      node.remove()
      grandParent.insertBefore(node, parent)
      if (!isEmptyOrBlankNode(topNode)) {
        grandParent.insertBefore(topNode, node)
      }
      if (isEmptyOrBlankNode(parent)) {
        parent.remove()
      }

      parent = grandParent
      isSimplified = true
    } else {
      break
    }
  }
  return isSimplified
}


/**
 * 
 * @param {HTMLElement} node 
 * @returns {Node | null}
 */
export function cleanListNode (node) {
  /** @type {Node?} */
  let updatedNode = node
  // If there is no `LI` child node, remove the `UL` or `OL`.
  if (!node.querySelector('li')) {
    node.remove()
    updatedNode = null
  }

  return updatedNode
}


/**
 * 
 * @param {Element} node 
 */
function cleanAttributes (node) {
  const attributes = Array.from(node.attributes)
  for (const attr of attributes) {
    const name = attr.nodeName
    const val = attr.nodeValue

    let shouldRemoveAttr = false
    if (!val || !val.trim()) {
      shouldRemoveAttr = true
    } else {
      const lowerName = name.toLowerCase()

      // Handle HTML `data` attribute. Remove all
      // attributes if they're not in the allowed
      // attributes or do not start with `data-upnote`.
      if (lowerName.startsWith('data')) {
        if (allowedDataAttributes.has(name)) {
          shouldRemoveAttr = false
        } else if (lowerName.startsWith('data-upnote')) {
          shouldRemoveAttr = false
        } else {
          shouldRemoveAttr = true
        }
      } else if (!allowedAttributesSet.has(lowerName)) {
        shouldRemoveAttr = true
      }
    }

    if (shouldRemoveAttr) {
      node.removeAttribute(name)
    }
  }
}

/**
 * 
 * @param {Element} updatedNode 
 */
function cleanAttributesIfNeeded (updatedNode) {
  if (!updatedNode) return

  cleanAttributes(updatedNode)

  // Sanitize class, only keep class with 'shine' prefix
  if (!updatedNode.hasAttribute(dataUpNoteKeepStyle)) {
    if (updatedNode.classList.length > 0) {
      removeClassIfNeeded(updatedNode, 'shine-editor')
      const classList = [...updatedNode.classList]
      for (const cls of classList) {
        if (!cls.startsWith('shine')) {
          updatedNode.classList.remove(cls)
        }
      }
      if (updatedNode.classList.length === 0) {
        removeAttributeIfNeeded(updatedNode, 'class')
      }
    }

    // Sanitize style, only keep 'width'
    removeStyle(updatedNode)
  }
}

/**
 * 
 * @param {HTMLElement|DocumentFragment} node 
 */
export function cleanAttributesForChildren (node) {
  const children = node.querySelectorAll('*')
  for (const child of children) {
    cleanAttributesIfNeeded(child)
  }
}

/**
 * 
 * @param {Range} range 
 * @param {Node} root 
 * @returns 
 */
export function getRangeRestorationState (range, root) {
  return {
    start: getNodeAtRangeOffset(range, root, true),
    startContainer: range.startContainer,
    startOffset: range.startOffset,
    endContainer: range.endContainer,
    endOffset: range.endOffset,
    collapsed: range.collapsed
  }
}

/**
 * 
 * @param {{start: Node; startContainer: Node; startOffset: number; endContainer: Node; endOffset: number; collapsed: boolean}} token 
 */
export function restoreRangeFromState (token) {
  const {
    start,
    startContainer,
    startOffset,
    endContainer,
    endOffset,
    collapsed
  } = token

  if (startContainer instanceof Text && endContainer instanceof Text &&
    startContainer.isConnected && endContainer.isConnected) {
    const newRange = document.createRange()
    newRange.setStart(startContainer, startOffset)
    newRange.setEnd(endContainer, endOffset)
    updateSelectionRange(newRange)
    return true
  } else if (start.isConnected && collapsed) {
    selectNodeContents(start, true, true)
    return true
  }
  return false
}

/**
 * Sometimes browser creates multiple adjacent nodes with the same tag.
 * For example, Gboard on Android may create multiple nodes when the text
 * contains multiple lines. Or on most browser, when hit enter key,
 * the browser will create a new node instead of new line on the existing
 * node. We can merge all previous nodes into the latest node and the range
 * is automatically maintained.
 * @param {HTMLElement|null|undefined} beforeInputEl 
 * @param {HTMLElement} root 
 * @param {string[]} nodeNames 
 */
export function mergeAdjacentBlockNodes (beforeInputEl, root, nodeNames) {
  if (!beforeInputEl) return
  
  const range = getRangeInNode(root)
  if (!range) return

  const start = getNodeAtRangeOffset(range, root, true)
  const currentEl = findNodeInTags(start, nodeNames, root)

  if (currentEl && 
    currentEl !== beforeInputEl && 
    currentEl.nodeName === beforeInputEl.nodeName && 
    currentEl instanceof HTMLElement) {

    const rangeState = getRangeRestorationState(range, root)
    const fragment = document.createDocumentFragment()
    let node = beforeInputEl
    while (node && node !== currentEl &&
      node.nodeName === currentEl.nodeName &&
      isBeforeReferenceNode(node, currentEl)) {
      const nextSibling = node.nextSibling
      const shouldAddBr = !isNodeEndsWithNewLine(node)
      // Move content of next to fragment
      for (const child of Array.from(node.childNodes)) {
        child.remove()
        fragment.append(child)
      }
      if (shouldAddBr) {
        fragment.append(document.createElement('br'))
      }
      node.remove()

      if (nextSibling && nextSibling instanceof HTMLElement) {
        node = nextSibling
      } else {
        break
      }
    }

    currentEl.prepend(fragment)
    restoreRangeFromState(rangeState)
  }  
}

/**
 * 
 * @param {Range} range 
 * @returns 
 */
export const deletePrefix = (range) => {
  if (!range) {
    return
  }
  const start = range.startContainer
  if (!(start instanceof Text)) {
    return
  }
  const endOffset = range.endOffset

  if (endOffset === start.length) {
    let br
    const nextSibling = start.nextSibling
    if (nextSibling instanceof HTMLBRElement) {
      nextSibling.remove()
      br = nextSibling
    } else {
      br = document.createElement('br')
    }

    let shouldCreateNewDiv = true
    const parent = start.parentNode
    if (parent instanceof HTMLDivElement && parent.childNodes.length === 1) {
      shouldCreateNewDiv = false
    }

    let newNode
    if (shouldCreateNewDiv) {
      const div = document.createElement('div')
      div.append(br)
      newNode = div
    } else {
      newNode = br
    }
    
    start.replaceWith(newNode)
    selectNodeContents(newNode, true, true)
  } else {
    const newRange = range.cloneRange()
    newRange.setStart(start, 0)
    newRange.setEnd(range.endContainer, endOffset)
    updateSelectionRange(newRange)
    newRange.deleteContents()
  }
}

/**
 * 
 * @param {HTMLElement} root 
 */
export function cleanEmptyNodesAtRange (root) {
  const range = getRangeInNode(root)
  if (!range) return
  const start = getNodeAtRangeOffset(range, root, true)
  const nonEditableEl = findNodeWithCondition(start, isNonEditableNode, root)
  if (nonEditableEl instanceof HTMLElement && isEmptyOrBlankNode(nonEditableEl)) {
    nonEditableEl.remove()
    return
  }
  
  const liNode = findNodeWithTag(start, 'LI', root)
  if (liNode instanceof HTMLElement && isEmptyOrBlankNode(liNode)) {
    const parent = liNode.parentNode
    liNode.remove()
    if (parent instanceof HTMLElement && parent.childElementCount === 0) {
      parent.remove()
    }
  }
}