import { PseudoElementClickManager } from './pseudoElementClickManager'
import autoBind from 'auto-bind'

export class CollapsibleEventManager {
  /**
   * 
   * @param {{collapseArrowClickListener?: (section: HTMLElement) => void, sectionOptionClickListener?: (section: HTMLElement) => void}} options 
   */
  constructor (options = {}) {
    this.collapseArrowClickListener = options.collapseArrowClickListener
    this.sectionOptionClickListener = options.sectionOptionClickListener
    autoBind(this)
    
    this.cssBeforeClickManager = new PseudoElementClickManager({
      isValidElement: this.isSectionTitleWrapper,
      pseudoElementClickListener: this.handleBeforeClick,
      type: ':before'
    })
    this.cssAfterClickManager = new PseudoElementClickManager({
      isValidElement: this.isSectionTitleWrapper,
      pseudoElementClickListener: this.handleAfterClick,
      type: ':after'
    })
  }

  /**
   * 
   * @param {Element} el 
   * @returns 
   */
  isSectionTitleWrapper (el) {
    const clsList = el.classList
    return clsList && clsList.contains('shine-section-title-wrapper')
  }

  /**
   * 
   * @param {Node} el 
   */
  handleBeforeClick (el) {
    const section = el.parentNode
    if (section && 
        section instanceof HTMLElement &&
        section.classList.contains('shine-collapsible-section')) {
      section.classList.toggle('shine-section-collapsed')
      if (this.collapseArrowClickListener) {
        this.collapseArrowClickListener(section)
      }
    }
  }

  /**
   * 
   * @param {Node} el 
   */
  handleAfterClick (el) {
    const section = el.parentNode
    if (section &&
        section instanceof HTMLElement &&
        section.classList.contains('shine-collapsible-section')) {
      if (this.sectionOptionClickListener) {
        this.sectionOptionClickListener(section)
      }
    }
  }

  /**
   * @param {MouseEvent | TouchEvent} e 
   */
  onMouseDown (e) {
    this.cssBeforeClickManager.onMouseDown(e)
    this.cssAfterClickManager.onMouseDown(e)
  }

  /**
   * @param {MouseEvent | TouchEvent} e 
   */
  onMouseMove (e) {
    this.cssBeforeClickManager.onMouseMove(e)
    this.cssAfterClickManager.onMouseMove(e)
  }

  /**
   * @param {MouseEvent | TouchEvent} e 
   */
  onMouseUp (e) {
    this.cssBeforeClickManager.onMouseUp(e)
    this.cssAfterClickManager.onMouseUp(e)
  }

  /**
   * @param {MouseEvent | TouchEvent} e 
   */
  onMouseLeave (e) {
    this.cssBeforeClickManager.onMouseLeave(e)
    this.cssAfterClickManager.onMouseLeave(e)
  }
}