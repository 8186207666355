export default class DelayHelper {

  /**
   * @param {number} milliseconds 
   * @returns 
   */
  static delay (milliseconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds)
    })
  }
}