import { dataConstants } from './utils/attributesHelper'
import { removeAttributeIfNeeded } from './utils/domHelper'

const { dataUpNoteMarkerDigitCount } = dataConstants

/**
 * 
 * @param {Node} el 
 * @returns 
 */
export function isFirstLevelNumberList (el) {
  if (!el) return false
  const parent = el.parentNode
  if (!parent) return false
  return el instanceof HTMLOListElement &&
    !(parent instanceof HTMLOListElement) &&
    !(parent instanceof HTMLUListElement) &&
    !(parent instanceof HTMLLIElement)
}

/**
 * 
 * @param {HTMLElement | DocumentFragment} root 
 */
export function updateListNumber (root) {
  const olNodes = root.querySelectorAll('ol')

  let start = 0
  let prevOl
  for (const ol of olNodes) {
    if (isFirstLevelNumberList(ol)) {
      if (ol.classList.contains('shine-continue-counter')) {
        if (prevOl) {
          start += prevOl.querySelectorAll(':scope > li').length
        } else {
          start = ol.start
        }
      } else {
        start = ol.start
      }

      if (start !== 1) {
        ol.setAttribute('start', `${start}`)
      } else {
        removeAttributeIfNeeded(ol, 'start')
      }

      prevOl = ol
    }

    const maxMarkerNumber = ol.start - 1 + ol.querySelectorAll(':scope > li').length
    const markerCount = ol.getAttribute(dataUpNoteMarkerDigitCount)
    const newMarkerCount = `${maxMarkerNumber}`.length
    const newMarkerCountStr = `${newMarkerCount}`
    if (markerCount !== newMarkerCountStr) {
      if (maxMarkerNumber >= 10) {
        ol.setAttribute(dataUpNoteMarkerDigitCount, newMarkerCountStr)
      } else {
        removeAttributeIfNeeded(ol, dataUpNoteMarkerDigitCount)
      }
    }
  }
}