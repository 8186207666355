import { dataConstants, shineIframeContainer } from './attributesHelper'

const { dataElementId, dataNonEditable, dataUpNoteTag } = dataConstants

const blockNodeNames = new Set([
  'DIV', 'UL', 'OL', 'LI', 'IMG', 'PRE', 'BLOCKQUOTE', 'TABLE',
  'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'HR', 'VIDEO', 'TD'
])

/**
 * @param { Node } node
 */
export function isNonEditableNode (node) {
  if (!node || !(node instanceof HTMLElement)) {
    return false
  }
  if (node.hasAttribute && node.hasAttribute(dataNonEditable)) {
    return true
  }
  return false
}

/**
 * @param { Node } node
 */
export function isHashTagNode (node) {
  if (!node || !(node instanceof HTMLElement)) {
    return false
  }
  if (node.hasAttribute && node.hasAttribute(dataUpNoteTag)) {
    return true
  }
  return false
}

/**
 * @param { Node } node
 */
export function isSectionTitle (node) {
  if (!node || !(node instanceof HTMLElement)) {
    return false
  }
  return node.classList.contains('shine-section-title')
}

/**
 * @param { Node } node
 */
export function isSectionTitleInner (node) {
  if (!node || !(node instanceof HTMLElement)) {
    return false
  }
  return node.classList.contains('shine-section-title-inner')
}

/**
 * @param { Node } node
 */
export function isSectionContent (node) {
  if (!node || !(node instanceof HTMLElement)) {
    return false
  }
  return node.classList.contains('shine-section-content')
}

/**
 * @param { Node } node
 */
export function isCollapsibleSection (node) {
  if (!node || !(node instanceof HTMLElement)) {
    return false
  }
  return node.classList.contains('shine-collapsible-section')
}

/**
 * @param { Node } node
 */
export function isSectionCollapsed (node) {
  if (!node || !(node instanceof HTMLElement)) {
    return false
  }
  return node.classList.contains('shine-section-collapsed')
}

/**
 * @param { Node } node
 */
export function isIframeContainer (node) {
  if (!node || !(node instanceof HTMLElement)) {
    return false
  }
  return node.classList.contains(shineIframeContainer)
}

/**
 * @param { Node } node
 */
export function isTableWrapper (node) {
  if (!node || !(node instanceof HTMLElement)) {
    return false
  }
  return node.classList.contains('shine-table-wrapper')
}

/**
 * @param { Node } node
 */
export function isNodeWithDataElementId (node) {
  if (!node || !(node instanceof HTMLElement)) {
    return false
  }
  return node.hasAttribute(dataElementId)
}

/**
 * @param { Node? } node
 */
export function isListNode (node) {
  if (!node) return false
  if (node instanceof HTMLUListElement || node instanceof HTMLOListElement) {
    return true
  }
  return false
}

/**
 * @param { Node? } node
 */
export function isBlockNode (node) {
  if (!node) return false
  if (blockNodeNames.has(node.nodeName)) {
    return true
  }
  return false
}

/**
 * 
 * @param { Node? } node 
 */
export function isPlainDivNode (node) {
  if (node instanceof HTMLDivElement && node.attributes.length === 0) {
    return true
  }
  return false
}