// @ts-nocheck
function replaceChildrenPolyfill (win = window) {	
  if(!win.Node.prototype.replaceChildren) {
    win.Node.prototype.replaceChildren = function (newChildren) {
      this.textContent = ''
      if (newChildren) {
        this.append(newChildren)
      }
    }
  }
}

/**
 * 
 * @param {Window} win 
 */
export function applyJSPolyfills (win = window) {
  replaceChildrenPolyfill(win)
}