import { getCoordinateOfPseudoElement, getEventClientX, getEventClientY } from './utils/domHelper'
import { Rect } from './utils/rect'

export class PseudoElementClickManager {
  /**
   * 
   * @param {{pseudoElementClickListener: (node: Node) => void, isValidElement: (node: Node) => boolean, type: string}} options 
   */
  constructor (options) {
    this.pseudoElementClickListener = options.pseudoElementClickListener
    this.isValidElement = options.isValidElement
    this.type = options.type || ':before'
  }

  /**
   * 
   * @param {HTMLElement} el 
   * @returns 
   */
  getPseudoElementCoordinate (el) {
    if (!this.pseudoElCoordinate) {
      this.pseudoElCoordinate = getCoordinateOfPseudoElement(el, this.type)
    }
    return this.pseudoElCoordinate
  }

  /**
   * 
   * @param {MouseEvent | TouchEvent} e 
   * @param {DOMRect} targetRect 
   * @param {{left: number, marginLeft: number, top: number, width: number, height: number}} style 
   * @returns 
   */
  isClickingPseudoElement (e, targetRect, style) {
    const clientX = getEventClientX(e)
    const clientY = getEventClientY(e)
    if (clientX === null || clientY === null) {
      return false
    }

    // Check if the clientX lies between left and right boundary
    // of the arrow
    const padding = 5
    const startX = targetRect.left + style.left + style.marginLeft
    const startY = targetRect.top + style.top
    const pseudoRect = new Rect(startX, startY, style.width + padding, style.height + padding)
    return pseudoRect.containsPoint(clientX, clientY)
  }

  /**
   * 
   * @param {MouseEvent | TouchEvent} e 
   */
  onMouseDown (e) {
    this.pseudoElCoordinate = undefined
    this.pseudoData = undefined

    const el = e.target
    if (el && el instanceof HTMLElement && this.isValidElement(el)) {
      const pseudoElCoordinate = this.getPseudoElementCoordinate(el)
      const targetRect = el.getBoundingClientRect()
      if (this.isClickingPseudoElement(e, targetRect, pseudoElCoordinate)) {
        this.pseudoData = {
          el: el,
          targetRect: targetRect,
          pseudoElCoordinate: pseudoElCoordinate
        }
        e.preventDefault()
      }
    }
  }

  /**
   * 
   * @param {MouseEvent | TouchEvent} e 
   */
  onMouseMove (e) {
    const el = e.target
    if (this.pseudoData && this.pseudoData.el === el) {
      const { targetRect, pseudoElCoordinate } = this.pseudoData
      if (this.isClickingPseudoElement(e, targetRect, pseudoElCoordinate)) {
        return
      }
    }
    this.pseudoData = undefined
  }

  /**
   * 
   * @param {MouseEvent | TouchEvent} e 
   */
  onMouseUp (e) {
    const target = e.target
    if (this.pseudoData && this.pseudoData.el === target && target instanceof HTMLElement) {
      if (this.pseudoElementClickListener) {
        this.pseudoElementClickListener(target)
      }
    }
    this.pseudoData = undefined
  }

  /**
   * 
   * @param {MouseEvent | TouchEvent} _e 
   */
  onMouseLeave (_e) {
    this.pseudoData = undefined
  }
}