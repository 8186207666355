export class Rect {
  /**
   * 
   * @param {number} x 
   * @param {number} y 
   * @param {number} w 
   * @param {number} h 
   */
  constructor (x, y, w, h) {
    this.x = x
    this.y = y
    this.width = w
    this.height = h
  }

  /**
   * 
   * @param {number} x 
   * @param {number} y
   */
  containsPoint (x, y) {
    return this.x <= x && 
      x <= this.x + this.width &&
      this.y <= y &&
      y <= this.y + this.height
  }
}

/**
 * 
 * @param {DOMRect} rect 
 * @param {number} x 
 * @param {number} y 
 */
export function domRectContainsPoint (rect, x, y) {
  return rect.x <= x && x <= rect.x + rect.width && rect.y <= y && y <= rect.y + rect.width
}