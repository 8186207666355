import '../scss/share.scss'

import { CollapsibleEventManager } from '../libs/shine/collapsibleEventManager'
import { updateListNumber } from '../libs/shine/numberListManager'
import { getInnerText } from '../libs/shine/utils/innerTextHelper'
import { convertFormulaToSVGElement } from '../libs/shine/utils/formulaHelper'
import { renderAllIframes } from '../libs/shine/embedRender'
import { findNodeWithCondition, isTouchSupported, removeClassIfNeeded, selectNodeContents, updateSelectionRange } from '../libs/shine/utils/domHelper'
import DelayHelper from '../libs/shine/utils/delayHelper'
import { isCollapsibleSection, isSectionCollapsed } from '../libs/shine/utils/findNodeHelper'

/** @type {HTMLElement} */
let shineRoot

/**
 * 
 * @param {HTMLElement} root 
 */
export function previewShineContent (root) {
  updateListNumber(root)

  const preEls = root.querySelectorAll('pre')
  for (const el of preEls) {
    const innerText = getInnerText(el)

    if (innerText && innerText.trim()) {
      const languageCode = el.getAttribute('data-code-language')
      let languages = null
      if (languageCode) {
        languages = [languageCode]
      }
      const html = window.hljs.highlightAuto(innerText, languages).value
      el.innerHTML = html
    }
  }

  const formulaEls = root.querySelectorAll('span[data-upnote-formula]')
  if (formulaEls) {
    for (const span of formulaEls) {
      const formula = span.getAttribute('data-upnote-formula')
      if (formula) {
        const svgNode = convertFormulaToSVGElement(formula)
        span.replaceWith(svgNode)
      }
    }
  }

  renderAllIframes(root)
}

const collapsibleEventManager = new CollapsibleEventManager({})

function onMouseDown (e) {
  collapsibleEventManager.onMouseDown(e)
}

function onMouseMove (e) {
  collapsibleEventManager.onMouseMove(e)
}

function onMouseUp (e) {
  collapsibleEventManager.onMouseUp(e)
}

function onMouseLeave (e) {
  collapsibleEventManager.onMouseLeave(e)
}

/**
 * 
 * @param {PointerEvent} e 
 */
function onClick (e) {

  const target = e.target

  if (!target || !(target instanceof HTMLAnchorElement)) {
    return
  }

  scrollViewToHref(target.href, () => {
    e.preventDefault()
  })
}

/**
 * 
 * @param {Element} el 
 */
async function temporaryZoomOnEl (el) {
  // Wait for scrolling finish before showing
  // grow animation
  await DelayHelper.delay(600)
  el.classList.add('grow', 'temporary-zoom')
  await DelayHelper.delay(400)
  el.classList.remove('temporary-zoom')
  await DelayHelper.delay(200)
  removeClassIfNeeded(el, 'grow')
}

/**
 * @param {string} href 
 * @param {(() => void) | null} beforeScrollCallback 
 */
function scrollViewToHref (href, beforeScrollCallback = null) {
  if (!href) return
  try {
    const url = new URL(href)
    const hash = url.hash

    if (hash && hash.length > 1) {
      const searchParams = new URLSearchParams(hash.substring(1))
      const elementId = searchParams.get('elementId')
      if (elementId) {
        const el = document.querySelector(`[data-element-id="${elementId}"]`)
        if (el instanceof HTMLElement) {
          if (beforeScrollCallback) {
            beforeScrollCallback()
          }
          const section = findNodeWithCondition(el, isCollapsibleSection, shineRoot)
          if (section && isSectionCollapsed(section)) {
            removeClassIfNeeded(section, 'shine-section-collapsed')
          }

          el.scrollIntoView(true)

          if (el.nodeName.startsWith('H')) {
            temporaryZoomOnEl(el)
          }
        }
      }
    }
  } catch (error) {
    // Do nothing
    console.error('error', error)
  }
}

function copyShineEditor () {
  if (!shineRoot) return

  const sel = window.getSelection()
  if (!sel) return

  /** @type {Range|null} */
  let range = null
  if (sel.rangeCount > 0) {
    range = sel.getRangeAt(0)
  }

  selectNodeContents(shineRoot, false, false)
  document.execCommand('copy')
  updateSelectionRange(range)

  const tooltipWrapper = document.querySelector('.copy-tooltip-wrapper')
  if (tooltipWrapper) {
    tooltipWrapper.style.display = 'flex'
    setTimeout(() => {
      tooltipWrapper.style.display = 'none'
    }, 1000)
  }
}

const flagBtn = document.getElementById('flag-btn')
const copyBtn = document.getElementById('copy-btn')

if (flagBtn && flagBtn instanceof HTMLAnchorElement) {
  const href = window.location.href
  if (href.includes('/share/notes/')) {
    flagBtn.style.display = 'unset'
    if (copyBtn) {
      copyBtn.style.display = 'unset'
    }
  }
  const subject = encodeURIComponent('Report link')
  const body = encodeURIComponent(`Please include a reason for reporting this link:\r\n\r\n${href}`)
  flagBtn.href = `mailto:support@getupnote.com?subject=${subject}&body=${body}`
}

if (copyBtn) {
  copyBtn.addEventListener('click', copyShineEditor)
}

document.addEventListener('DOMContentLoaded', () => {
  const root = document.getElementById('shine-editor')
  shineRoot = root

  previewShineContent(root)

  if (isTouchSupported) {
    root.addEventListener('touchstart', onMouseDown)
    root.addEventListener('touchmove', onMouseMove)
    root.addEventListener('touchend', onMouseUp)
  }
  root.addEventListener('mousedown', onMouseDown)
  root.addEventListener('mousemove', onMouseMove)
  root.addEventListener('mouseup', onMouseUp)
  root.addEventListener('mouseleave', onMouseLeave)
  root.addEventListener('click', onClick)

  scrollViewToHref(window.location.href)
})