// https://github.com/bleaklake/TeX-to-SVG/blob/master/TeXToSVG.js
import { mathjax } from 'mathjax-full/js/mathjax.js'
import { TeX } from 'mathjax-full/js/input/tex.js'
import { SVG } from 'mathjax-full/js/output/svg.js'
import { liteAdaptor } from 'mathjax-full/js/adaptors/liteAdaptor.js'
import { RegisterHTMLHandler } from 'mathjax-full/js/handlers/html.js'
import { AssistiveMmlHandler } from 'mathjax-full/js/a11y/assistive-mml.js'

import { AllPackages } from 'mathjax-full/js/input/tex/AllPackages.js'


const DEFAULT_OPTIONS = {
    width: 1280,
    ex: 8,
    em: 16,
}

const ASSISTIVE_MML = false, FONT_CACHE = true, INLINE = false, packages = AllPackages.sort().join(', ').split(/\s*,\s*/)

const adaptor = liteAdaptor()
const handler = RegisterHTMLHandler(adaptor)
if (ASSISTIVE_MML) AssistiveMmlHandler(handler)

const tex = new TeX({ packages })
const svg = new SVG({ fontCache: (FONT_CACHE ? 'local' : 'none') })
const html = mathjax.document('', { InputJax: tex, OutputJax: svg })

const CSS = [
    'svg a{fill:blue;stroke:blue}',
    '[data-mml-node="merror"]>g{fill:red;stroke:red}',
    '[data-mml-node="merror"]>rect[data-background]{fill:yellow;stroke:none}',
    '[data-frame],[data-line]{stroke-width:70px;fill:none}',
    '.mjx-dashed{stroke-dasharray:140}',
    '.mjx-dotted{stroke-linecap:round;stroke-dasharray:0,140}',
    'use[data-c]{stroke-width:3px}'
].join('')

/**
 * 
 * @param {string} formula 
 * @param {{width?: number; ex?: number; em?: number}?} opts 
 * @returns 
 */
function TeXToSVG (formula, opts = null) {
    const options = opts ? { ...DEFAULT_OPTIONS, ...opts } : DEFAULT_OPTIONS

    const node = html.convert(formula, {
        display: !INLINE,
        em: options.em,
        ex: options.ex,
        containerWidth: options.width
    })

    const svgString = adaptor.innerHTML(node)
    return svgString.replaceAll('<defs>', `<defs><style>${CSS}</style>`)
}

export default TeXToSVG