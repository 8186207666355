import { applyJSPolyfills } from './polyfills'

/**
 * 
 * @param {Node} node 
 * @returns 
 */
export function getTextContent (node) {
  return node.textContent || ''
}

const noTextSelector = 'img, iframe, video'
const contentEditable = 'contenteditable'

/** @type {HTMLElement | null} */
let iframeBody = null

/** @type {Selection | null} */
let iframeSelection = null

/**
 * 
 * @param { HTMLElement | Text } node
 * @returns {string}
 */
export function getInnerText (node) {
  if (node instanceof Text) {
    return node.textContent || ''
  }

  // el.innerText does not always return the text as displayed
  // in the browser, sometimes it adds new lines arbitrarily.
  // While window.getSelection().toString() is more accurate,
  // it's difficult to change and restore the selection. Hence,
  // we use an iframe so that it doesn't affect the selection
  // on main window, while allow the iframe to has selection
  // on its own. The iframe needs to be positioned so that it's
  // not visible in the main window.
  if (!iframeBody) {
    const innerTextIframe = document.createElement('iframe')
    innerTextIframe.style.borderWidth = `0px`
    innerTextIframe.style.position = 'fixed'
    innerTextIframe.style.top = `-10000px`
    innerTextIframe.style.left = `-10000px`
    innerTextIframe.style.zIndex = `-1000`
    document.body.append(innerTextIframe)

    const contentWindow = innerTextIframe.contentWindow
    if (contentWindow) {
      applyJSPolyfills(contentWindow)
      iframeBody = contentWindow.document.body
      iframeSelection = contentWindow.getSelection()
      iframeBody.style.setProperty('-webkit-touch-callout', 'none')
    }
  }

  if (!iframeBody || !iframeSelection) {
    return ''
  }


  let shouldClone = false
  let clone = node

  // If node has a parent, or has images/iframe/video,
  // it must be cloned the original node is not affected.
  // Needs to remove contenteditable attribute as well,
  // otherwise selection.toString() will return empty string.
  if (node.parentNode ||
    node.hasAttribute(contentEditable) ||
    node.querySelector(`${noTextSelector}, [${contentEditable}]`)) {
    shouldClone = true
  }

  if (shouldClone) {
    clone = /**@type {HTMLElement} */ (node.cloneNode(true))

    const noTextEls = clone.querySelectorAll(noTextSelector)
    for (const noTextEl of noTextEls) {
      noTextEl.remove()
    }

    clone.removeAttribute(contentEditable)
    const editableEls = clone.querySelectorAll(`[${contentEditable}]`)
    for (const editableEl of editableEls) {
      editableEl.removeAttribute(contentEditable)
    }
  }

  iframeBody.replaceChildren(clone)
  iframeSelection.selectAllChildren(iframeBody)
  const text = iframeSelection.toString()

  // Remove clone from iframe to restore original state of clone.
  clone.remove()

  return text
}

/**
 * Get title of a element.
 * @param { HTMLElement } el 
 */
export function getLinkedElementText (el) {
  const text = getInnerText(el).trim()
  const parts = text.split('\n', 1)
  if (parts.length > 0) {
    return parts[0]
  }
  return text
}