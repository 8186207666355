import { dataConstants, shineIframeContainer} from './utils/attributesHelper'
import { findNodeWithCondition } from './utils/domHelper'
import { isCollapsibleSection, isSectionCollapsed } from './utils/findNodeHelper'

const { dataUpNoteIframe, dataUpNoteIframeRatio, dataUpNoteKeepStyle, dataNonEditable } = dataConstants
/**
 * 
 * @param {string | null} ratio 
 * @returns 
 */
export function createIframeWrapper (ratio) {
  const wrapper = document.createElement('div')
  wrapper.className = shineIframeContainer
  wrapper.setAttribute(dataUpNoteKeepStyle, 'true')
  wrapper.setAttribute(dataNonEditable, 'true')
  if (ratio !== null) {
    wrapper.style.setProperty('padding-bottom', ratio)
  }
  return wrapper
}

/**
 * 
 * @param {HTMLElement | DocumentFragment} root 
 */
export function renderAllIframes (root) {
  // Do not render embed if it's inside a collapsed section.
  const isRootSection = isCollapsibleSection(root)
  if (isRootSection && isSectionCollapsed(root)) {
    return
  }

  const iframes = root.querySelectorAll(`iframe[${dataUpNoteIframeRatio}]`)
  if (iframes && iframes.length > 0) {
    for (const iframe of iframes) {
      const parent = iframe.parentNode
      if (parent instanceof HTMLElement &&
        !parent.classList.contains(shineIframeContainer)) {
        const clone = iframe.cloneNode(true)
        const ratio = iframe.getAttribute(dataUpNoteIframeRatio)
        const wrapper = createIframeWrapper(ratio)
        wrapper.append(clone)
        iframe.replaceWith(wrapper)
      }
    }
  }
  
  const embedEls = root.querySelectorAll(`a[${dataUpNoteIframe}]`)
  if (embedEls && embedEls.length > 0) {
    for (const el of embedEls) {
      if (!isRootSection) {
        const section = findNodeWithCondition(el, isCollapsibleSection, root)
        if (section && isSectionCollapsed(section)) {
          continue
        }
      }

      const ratio = el.getAttribute(dataUpNoteIframeRatio)
      if (ratio) {
        const iframeHTML = el.getAttribute(dataUpNoteIframe)
        if (iframeHTML) {
          const wrapper = createIframeWrapper(ratio)
          wrapper.innerHTML = iframeHTML
          const iframe = wrapper.querySelector('iframe')
          if (iframe && !iframe.hasAttribute('allowfullscreen')) {
            iframe.setAttribute('allowfullscreen', 'true')
          }
          el.replaceWith(wrapper)
        }
      }
    }
  }
}

export function stopAllVideos () {
  const iframes = document.querySelectorAll(`iframe[${dataUpNoteIframeRatio}]`)
  for (const iframe of iframes) {
    const newIframe = iframe.cloneNode(true)
    iframe.replaceWith(newIframe)
  }
}