import TeXToSVG from './texToSVG'

/**
 * 
 * @param {string} formula 
 * @returns {string}
 */
export function convertFormulaToSVG (formula) {
  return convertFormulaToSVGElement(formula).outerHTML
}

/**
 * 
 * @param {string} formula 
 * @returns {Element}
 */
export function convertFormulaToSVGElement (formula) {
  const svg = TeXToSVG(formula)
  const div = document.createElement('div')
  div.innerHTML = svg

  const errorNode = div.querySelector('[data-mjx-error]')
  if (errorNode) {
    let message = errorNode.getAttribute('data-mjx-error')
    if (!message) {
      message = `Error converting formula ${formula}`
    }
    throw Error(message)
  }

  const svgNode = div.querySelector('svg')
  if (!svgNode) {
    throw Error(`Unable to parse formula ${formula}`)
  }

  svgNode.remove()
  return svgNode
}