export const shineIframeContainer = 'shine-iframe-container'
export const shineTableWrapper = 'shine-table-wrapper'
export const shineContinueCounter = 'shine-continue-counter'

export const dataConstants = {
  dataUpNoteFormula: 'data-upnote-formula',
  dataFormulaWidthEm: 'data-formula-width-em',
  dataNonEditable: 'data-non-editable',
  dataElementId: 'data-element-id',
  dataUpNoteTag: 'data-upnote-tag',
  dataUpNoteKeepStyle: 'data-upnote-keep-style',
  dataUpNoteIframe: 'data-upnote-iframe',
  dataUpNoteIframeRatio: 'data-upnote-iframe-ratio',
  dataUpNoteTitle: 'data-upnote-title',
  dataChecked: 'data-checked',
  dataUpNotePlaceholder: 'data-upnote-placeholder',
  dataUpNotePlaceholderKey: 'data-upnote-placeholder-key',
  dataUpNoteFilePreview: 'data-upnote-file-preview',
  dataUpNoteRole: 'data-upnote-role',
  dataUpNoteType: 'data-upnote-type',
  dataUpNoteFileDisplay: 'data-upnote-file-display',
  dataUpNoteMarkerDigitCount: 'data-upnote-marker-digit-count',
  dataHref: 'data-href',
  dataFileId: 'data-file-id',
  dataCodeLanguage: 'data-code-language',
  dataUpNoteSectionContent: 'data-upnote-section-content',
  dataKeepColors: 'data-keep-colors',
  dataNoteId: 'data-note-id',
  dataUpNoteCopyFileUrl: 'data-upnote-copy-file-url',
  dataNotebookId: 'data-notebook-id',
  dataFilterId: 'data-filter-id',
  dataDrawingId: 'data-drawing-id'
}

export const allowedDataAttributes = new Set(Object.values(dataConstants))